<!--
 * @Author: your name
 * @Date: 2021-04-26 11:12:39
 * @LastEditTime: 2021-05-16 22:42:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\resumedownload\index.vue
-->
<template>
  <div>
    <navigation msg="简历收藏">
      <!-- <div @click="filtermeth" class="sh2">
        筛选<img src="../../assets/sanjiao.png" />
        <div v-show="selenum != 0" class="tiaojian">{{ selenum }}</div>
      </div> -->
    </navigation>
    <div class="allmsgclass">
      <div
        @click="tosub(item)"
        v-for="(item, index) in listmsg"
        v-bind:key="index"
      >
        <div class="msgccl">
          <div class="msgclass">
            <div class="xinxi">
              <div>
                <div class="userclass">
                  <img
                    v-if="(item.sex + '').indexOf('男') != -1"
                    class=""
                    src="../../assets/user_1.png"
                    alt=""
                  />
                  <img
                    v-if="(item.sex + '').indexOf('女') != -1"
                    class=""
                    src="../../assets/user_2.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="mac">
                <div class="listc">
                  <div class="nayu">
                    <div>{{ item.name }}</div>
                    <img
                      v-if="(item.sex + '').indexOf('男') != -1"
                      src="../../assets/sex_1.png"
                      alt=""
                    />
                    <img
                      v-if="(item.sex + '').indexOf('女') != -1"
                      src="../../assets/sex_2.png"
                      alt=""
                    />
                    <div>{{ item.politics }}</div>
                  </div>
                  <div>
                    <div class="kaic">{{ item.jop }}</div>
                  </div>
                </div>
                <div class="xiao">
                  <div>
                    {{ item.years_working }}年 |
                    {{ item.education_background }} |
                    {{ item.salary }}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="hycla">
            <div></div>
            <div>期望行业：{{ item.industry }}</div>
          </div>
          <div class="hycla hycla2">
            <div></div>
            <div>推荐公司：{{ item.company }}</div>
          </div>
          <div class="hycla hy2c">
            <div></div>
            <div>{{ item.add_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <filtermenu @shaimeth="shaimeth" ref="filterid"></filtermenu>
    <div class="nomsgclass"></div>
  </div>
</template>
<script>
import loading from "../../components/loading2/index";
import filtermenu from "../../components/filtermenu/index";
export default {
  components: {
    loading,
    filtermenu,
  },
  data() {
    return {
      selenum: 0,
      listmsg: "",
      pageno: 0,
      filterarray: ["", "", ""],
    };
  },
  methods: {
    filtermeth() {
      this.$refs.filterid.showfilter();
    },
    shaimeth(msg) {
      let a = 0;
      msg.forEach((e) => {
        if (e != "") {
          a++;
        }
      });
      this.selenum = a;
      this.pageno = 0;
      this.listmsg = [];
      this.filterarray = msg; //筛选内容
      this.$refs.loades.empty();
      this.loaddom();
    },
    loaddom() {
      // 下载简历
      this.getmsg("/firm/v1/Resume/myCollect", {
        reqType: "resume",
        pageno: ++this.pageno,
        limit: 15,
      });
    },
    tosub(item) {
      this.$router.push({
        path: "/sub",
        query: { id: btoa(item.id), is_download: item.is_download },
      });
    },
    getmsg(url, msg) {
      this.$refs.loades.loadhttpes(url, msg).then((res) => {
        this.listmsg = res.arraymsg;
      });
    },
  },
};
</script>
<style scoped>
.listc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yfx1 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.yfx2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.yfx2 span {
  color: #222222;
}
.pingjiacla {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.msgf {
  width: 100%;
}
.fxclass7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.22rem;
}

.fxclass5 {
  display: flex;
  align-items: center;
}
.fxclass5 > :first-child {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  padding: 0.05rem 0.1rem;
  background: #5fcd86;
  border-radius: 0.08rem;
  margin-right: 0.11rem;
}
.fxclass5 > :nth-child(2) {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  padding: 0.05rem 0.1rem;
  background: #96a5aa;
  border-radius: 0.08rem;
}
.fxclass4 > :first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.42rem;
  margin-right: 0.09rem;
}
.fxclass4 > :nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  line-height: 0.33rem;
}
.fxclass3 {
  width: 1.07rem;
  height: 1.07rem;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.3rem;
}
.fxclass3 img {
  width: 100%;
  height: 100%;
}
.fxclass2 {
  display: flex;
  align-items: center;
}
.fxclass {
  display: flex;
  align-items: center;
  padding: 0.46rem 0.28rem;
  background-color: #ffffff;
  border-radius: 0.1rem;
  margin-bottom: 0.14rem;
}
.nomsgclass {
  height: 0.3rem;
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  padding: 0.29rem 0;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  padding: 0.28rem 0;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}
.dingyue {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}

.msgclassse {
  margin: 0.35rem 0.32rem;
}

.allmsgclass >>> .van-popup__close-icon--top-left {
  top: 0.3rem;
}
.allmsgclass >>> .van-popup__close-icon {
  font-size: 0.46rem;
}

.hycla {
  display: flex;
  align-items: center;
  margin-top: 0.17rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.hycla > :first-child {
  min-width: 1.37rem;
}
.msgccl {
  background-color: #ffffff;
  padding: 0.46rem 0.23rem;
  border-radius: 0.1rem;
  margin-bottom: 0.14rem;
}
.kaic {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff5f19;
  line-height: 0.33rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 2.3rem;
  text-align: right;
}
.mac {
  margin-left: 0.3rem;
  width: 100%;
}
.xiao {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696969;
  line-height: 0.33rem;
  margin-top: 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xinxi {
  display: flex;
  align-items: center;
  width: 100%;
}
.nayu {
  display: flex;
  align-items: center;
}
.nayu img {
  width: 0.4rem;
  height: 0.4rem;
  margin: 0 0.14rem;
}
.nayu > div:first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.42rem;
}
.nayu > :nth-child(3) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  line-height: 0.33rem;
}
.userclass {
  width: 1.07rem;
  height: 1.07rem;
  border-radius: 50%;
  overflow: hidden;
}
.userclass img {
  width: 100%;
  height: 100%;
}
.allmsgclass {
  margin: 0.21rem 0.24rem;
}
.msgclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.daohang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.27rem 0.35rem;
  background-color: white;
}
.sh1 {
  display: flex;
  align-items: center;

  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b6b6b6;
  line-height: 0.48rem;
}
.sh1 > div {
  margin-right: 0.54rem;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.tiaojian {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  border-radius: 50%;
  background-color: #ff5f19;
  color: #ffffff;
  font-size: 0.2rem;
  top: -0.1rem;
  right: -0.1rem;
}
.sem {
  color: #222222;
}
.hy2c {
  margin-top: 0.14rem;
}
.hycla2 {
  margin-top: 0.14rem;
}
</style>
